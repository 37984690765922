@tailwind base;
@tailwind components;
@tailwind utilities;
.programs {
  box-shadow: 0 0 100px 0 rgb(0 0 0 / 50%);
}
.active-tab {
  background-color: #db3441;
  color: #fff;
}
.default-tab {
  background-color: #ddd;
  color: #3c247d;
}
